import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Fresno.css";

const banner = require("../../assets/cave/fresnobanner.png");
const college = require("../../assets/cave/collegecity.png");
const bulldog = require("../../assets/bulldog.png");

export const Fresno = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      <img
        src={bulldog}
        alt="bulldog"
        className="spin"
        style={{
          position: "absolute",
          left: `${1770 - scrollY }px`,
          bottom: "550px",
          width: "350px",
        }}
      ></img>
      <img
        src={college}
        alt="college"
        style={{
          position: "absolute",
          left: `${1300 - scrollY }px`,
          bottom: "0px",
          width: "1200px",
        }}
      ></img>
      <img
        src={banner}
        alt="fresnobanner"
        style={{
          position: "absolute",
          left: `${1500 - scrollY }px`,
          bottom: "300px",
        }}
      ></img>
      <div
        style={{
          position: "absolute",
          left: `${1675 - scrollY }px`,
          bottom: "520px",
          fontSize: "55px",
          lineHeight: "2em",
          transform: "skewY(8deg) skewX(356deg)",
        }}
      >
        Fresno
      </div>
      <div
        style={{
          position: "absolute",
          left: `${2070 - scrollY }px`,
          bottom: "565px",
          fontSize: "25px",
          lineHeight: "2em",
          transform: "skewY(344deg) skewX(356deg)",
        }}
      >
        State
      </div>
    </div>
  );
};
