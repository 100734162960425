import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./AWS.css";

export const AWSResources = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div
      className="aws-info"
      style={{
        height: "250px",
        left: `${3700 - scrollY }px`,
      }}
    >
      <h1
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        AWS Resources
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ marginLeft: "20px" }}>
          <li>API Gateway</li>
          <li>Lambda</li>
          <li>ECS</li>
          <li>ECR</li>
          <li>EC2</li>
          <li>VPC</li>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <li>Dynamo</li>
          <li>RDS</li>
          <li>S3</li>
          <li>SQS</li>
          <li>SNS</li>
          <li>SES</li>
          <li>CloudWatch</li>
        </div>
      </div>
    </div>
  );
};
