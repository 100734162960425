import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { useForm } from "react-cool-form";

import "./Form.css";

export const Form = () => {
  const scrollY = useAppSelector(scrollDistance);

  const { form, use } = useForm({
    defaultValues: { email: "", subject: "", message: "" },
    onSubmit: async (values) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Authorization, X-Requested-With",
        },
        body: JSON.stringify({
          email: values.email,
          subject: values.subject,
          message: values.message,
        }),
      };
      await fetch(
        "https://us-central1-portfolio-28d5c.cloudfunctions.net/sendMail",
        requestOptions
      )
        .then((response) => response)
        .then((data) =>
          alert(
            JSON.stringify(
              "Thank you, JC will get back to you soon.",
              undefined,
              2
            )
          )
        );
    },
  });

  const errors = use("errors");

  return (
    <div
      style={{
        position: "absolute",
        bottom: "200px",
        left: `${20500 - scrollY}px`,
        zIndex: "1",
        backgroundColor: "#542b6d",
        padding: "30px",
        borderRadius: "20px",
      }}
    >
      <form ref={form} noValidate>
        <div className="count divselect">Send JC a Message</div>
        <div className="divselect">
          <input
            className="inputselect"
            name="email"
            placeholder="Email"
            required
          />
          {errors.email && <p className="pstuff">{errors.email}</p>}
        </div>
        <div className="divselect">
          <input
            className="inputselect"
            name="subject"
            placeholder="Subject"
            required
          />
          {errors.subject && <p className="pstuff">{errors.subject}</p>}
        </div>
        <div className="divselect">
          <textarea
            className="noscrollbars"
            name="message"
            placeholder="Message"
            required
          ></textarea>

          {errors.message && <p className="pstuff">{errors.message}</p>}
        </div>
        <input className="inputselect" type="submit" />
      </form>
    </div>
  );
};
