import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./AWS.css";

const dynamo = require("../../assets/dynamo.png");
const rds = require("../../assets/rds.png");
const ec2 = require("../../assets/ec2.png");
const ecr = require("../../assets/ecr.png");
const ecs = require("../../assets/ecs.png");
const lambda = require("../../assets/lambda.png");
const s3 = require("../../assets/s3.png");
const sqs = require("../../assets/sqs.png");
const aws = require("../../assets/aws-b.png");
const clouds = require("../../assets/cave/cloud.png");


export const AWSCloud = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      <img
        className="cloud-stats-icon"
        src={ec2}
        alt="ec2"
        style={{
          bottom: "500px",
          left: `${3160 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={ecr}
        alt="ecr"
        style={{
          bottom: "500px",
          left: `${3240 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={ecs}
        alt="ecs"
        style={{
          bottom: "500px",
          left: `${3320 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={lambda}
        alt="lambda"
        style={{
          bottom: "500px",
          left: `${3400 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={rds}
        alt="rds"
        style={{
          bottom: "500px",
          left: `${3480 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={dynamo}
        alt="dynamo"
        style={{
          bottom: "500px",
          left: `${3590 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={s3}
        alt="s3"
        style={{
          bottom: "500px",
          left: `${3670 - scrollY }px`,
        }}
      />
      <img
        className="cloud-stats-icon"
        src={sqs}
        alt="sqs"
        style={{
          bottom: "500px",
          left: `${3750 - scrollY }px`,
        }}
      />
      <img
        src={clouds}
        alt="clouds"
        className="clouds"
        style={{
          left: `${2890 - scrollY }px`,
        }}
      ></img>
      <img
        className="aws-icon"
        src={aws}
        alt="aws"
        style={{
          width: "130px",
          height: "81px",
          bottom: "607px",
          left: `${3430 - scrollY }px`,
        }}
      />
    </div>
  );
};
