import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import { useMediaQuery } from "react-responsive";

export const ProjectsSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      {scrollY > 15500 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h1 style={{ margin: "0px" }}>Projects</h1>
                <h3 style={{ marginTop: "5px" }}>
                  In JC's free time he likes to work on his personal projects.
                  Including, but not limited to, mobile applications, websites,
                  and desktop apps.
                </h3>
                <h3>
                  As of late, he has been focusing much of his coding time on
                  mobile applications built in Flutter using Firebase as the
                  backend.
                </h3>
                <h3>
                  He included some clip's of a couple recent projects hes worked
                  on.
                </h3>
                <h3>
                  Sidenote: This website was a personal project built in React
                  with Typescript and hosted on Firebase.
                </h3>
              </div>
            </div>
          }
          parentLeft={`${16800 - scrollY / 1}px`}
          parentBottom={"320px"}
          parent2Left={`${16810 - scrollY / 1}px`}
          parent2Bottom={"330px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={isTabletOrMobile ? "360px" : "330px"}
        />
      )}
    </div>
  );
};
