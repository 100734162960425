import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import { useMediaQuery } from "react-responsive";
import "./Fresno.css";

export const FresnoSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      {scrollY > 100 && (
        <div>
          <SpeechBubble
            childComp={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ flex: "1" }}>
                  <h1 style={{ marginTop: "5px" }}> About JC</h1>
                  <div style={{ marginBottom: "5px" }}>
                    His name is Jesus Covarrubias but people call him JC.
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    He was born and raised in sunny (really hot) Fresno,
                    California.
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    He graduated from California State University, Fresno with a
                    bachelors of science degree in Computer Science.
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    He's a former student president of the Society of Hispanic
                    Professional Engineers (SHPE) Fresno chapter.
                  </div>
                  <h2>His Hobbies</h2>
                  <div style={{ marginBottom: "5px" }}>
                    Playing any sport (mainly baseball)
                  </div>
                  <div style={{ marginBottom: "5px" }}>Making music</div>
                  <div style={{ marginBottom: "5px" }}>Painting</div>
                  <div style={{ marginBottom: "5px" }}>
                    Coding his personal projects
                  </div>
                </div>
              </div>
            }
            parentLeft={`${2200 - scrollY}px`}
            parentBottom={"260px"}
            parent2Left={`${2210 - scrollY}px`}
            parent2Bottom={"270px"}
            triangleLeft={"650px"}
            triangleBottom={"170px"}
            triangleRotate={"310deg"}
            triangle2left={"660px"}
            triangle2Bottom={"180px"}
            triangle2Rotate={"310deg"}
            scaleX={"1"}
            height={isTabletOrMobile ? "380px" : "350px"}
          />
        </div>
      )}
    </div>
  );
};
