import { useMemo, useEffect, useState } from "react";
import { useRive, UseRiveParameters, Layout, Fit, Alignment } from "rive-react";
import { useAppSelector } from "./app/hooks";
import { scrollDirection } from "./features/scroll/scrollSlice";

export const RiveComp = () => {
  const direction = useAppSelector(scrollDirection);
  const [catDirection, setCatDirection] = useState(1);

  const parms: UseRiveParameters = {
    src: "../octocat_try.riv",
    autoplay: true,
    artboard: "New Artboard",
    animations: "Animation 1",
    layout: new Layout({ fit: Fit.Contain, alignment: Alignment.BottomCenter }),
  };

  const { rive, RiveComponent } = useRive(parms);
  useEffect(() => {
    if (rive) {
      if (direction === "UP") {
        setCatDirection(-1);
        if (!rive.isPlaying) {
          rive.play();
        }
      } else if (direction === "DOWN") {
        setCatDirection(1);
        if (!rive.isPlaying) {
          rive.play();
        }
      } else {
        rive.pause();
      }
    }
  }, [direction, rive]);

  return useMemo(
    () => (
      <div
        style={{
          height: "200px",
          bottom: "-12px",
          left: "50%",
          position: "absolute",
          overflow: "hidden",
          transform: `translateX(-50%) scaleX(${catDirection})`,
          transition: "all 500ms ease-out",
          zIndex: "2",
        }}
      >
        <RiveComponent />
      </div>
    ),
    [RiveComponent, catDirection]
  );
};
