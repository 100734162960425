import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ScrollState {
  value: number;
  status: string;
}

const initialState: ScrollState = {
  value: 0,
  status: "NONE",
};

export const scrollSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.value += 1;
    // },
    // Use the PayloadAction type to declare the contents of `action.payload`
    changeScrollDistance: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },

    changeScrollDirection: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
});

export const scrollDistance = (state: RootState) => state.scroll.value;
export const scrollDirection = (state: RootState) => state.scroll.status;

export const { changeScrollDistance, changeScrollDirection } =
  scrollSlice.actions;

export default scrollSlice.reducer;
