import { useAppSelector } from "../../../app/hooks";
import { scrollDistance } from "../../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../../components/SpeechBubble";
import "./Nike.css";

export const NikeSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 8500 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h3 style={{ marginTop: "5px" }}>
                  And now he currently works for Nike as a software engineer in
                  the innovation sector.
                </h3>
              </div>
            </div>
          }
          parentLeft={`${9500 - scrollY / 1}px`}
          parentBottom={"420px"}
          parent2Left={`${9510 - scrollY / 1}px`}
          parent2Bottom={"430px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={"60px"}
        />
      )}
    </div>
  );
};
