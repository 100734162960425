import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { useMediaQuery } from "react-responsive";
import "./Title.css";

const j = require("../../assets/cave/j.png");
const c = require("../../assets/cave/c.png");
const r2 = require("../../assets/cave/r2.png");
const e = require("../../assets/cave/e.png");
const s = require("../../assets/cave/s.png");
const u = require("../../assets/cave/u.png");
const m = require("../../assets/cave/m.png");
const apos = require("../../assets/cave/apos.png");


export const Title = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div
      className={scrollY < 1 ? "jc-resume-wrapper" : "jc-resume-wrapper-down"}
      style={{
        left: isTabletOrMobile ? `${15 - scrollY}px` : `${400 - scrollY}px`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img
          src={j}
          alt="j"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",
            background: "none",
          }}
        ></img>
        <img
          src={c}
          alt="c"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",
            background: "none",
          }}
        ></img>
        <img
          src={apos}
          alt="apos"
          style={{
            height: isTabletOrMobile ? "20px" : "auto",
            background: "none",
            marginBottom: "70px",
            //
          }}
        ></img>
        <img
          src={s}
          alt="s"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
        <div style={{ width: isTabletOrMobile ? "30px" : "60px" }} />
        <img
          src={r2}
          alt="r2"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
        <img
          src={e}
          alt="e"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
        <img
          src={s}
          alt="s"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
        <img
          src={u}
          alt="u"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
        <img
          src={m}
          alt="m"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
        <img
          src={e}
          alt="e"
          style={{
            height: isTabletOrMobile ? "50px" : "auto",

            background: "none",
          }}
        ></img>
      </div>
      <h1
        style={{
          fontSize: isTabletOrMobile ? "40px" : "70px",
          textAlign: "center",
          width: isTabletOrMobile ? "400px" : "552px",
        }}
      >
        Narrated by Octocat
      </h1>
      <h3
        style={{
          fontSize: isTabletOrMobile ? "20px" : "50px",
          textAlign: "center",
          width: isTabletOrMobile ? "400px" : "552px",
          margin: "0px"
        }}
      >
        Scroll Down
      </h3>
      <div className="mouse_scroll">
        <div className="mouse">
          <div className="wheel"></div>
        </div>
        <div>
          <div className="m_scroll_arrows unu"></div>
          <div className="m_scroll_arrows doi"></div>
          <div className="m_scroll_arrows trei"></div>
        </div>
      </div>
    </div>
  );
};
