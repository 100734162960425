import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Skills.css";

const flutter = require("../../assets/flutter.png");
const react = require("../../assets/react.png");
const html = require("../../assets/html.png");
const vue = require("../../assets/vue.png");

export const FrontEnd = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {/* Front End Skills */}
      <div
        className="stats"
        style={{
          display: "flex",
          left: `${6000 - scrollY / 1}px`,
        }}
      >
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
      </div>

      <div
        className="stats-competencies"
        style={{
          left: `${6000 - scrollY / 1}px`,
        }}
      />

      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "540px",
          left: `${6000 - scrollY / 1}px`,
        }}
      >
        BEGINNER
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "580px",
          left: `${6075 - scrollY / 1}px`,
        }}
      >
        KNOWLEDGEABLE
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "540px",
          left: `${6150 - scrollY / 1}px`,
        }}
      >
        INTERMEDIATE
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "580px",
          left: `${6225 - scrollY / 1}px`,
        }}
      >
        COMPETENT
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "540px",
          left: `${6300 - scrollY / 1}px`,
        }}
      >
        ADVANCED
      </div>
      {/* Programming */}

      {/* Flutter */}
      {scrollY > 5000 && (
        <img
          className="stats-icon-first"
          src={flutter}
          alt="flutter"
          style={{
            bottom: "130px",
            left: `${6015 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5050 && (
        <img
          className="stats-icon-second"
          src={flutter}
          alt="flutter"
          style={{
            bottom: "130px",
            left: `${6095 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5100 && (
        <img
          className="stats-icon-third"
          src={flutter}
          alt="flutter"
          style={{
            bottom: "130px",
            left: `${6180 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5150 && (
        <img
          className="stats-icon-fourth"
          src={flutter}
          alt="flutter"
          style={{
            bottom: "130px",
            left: `${6260 - scrollY / 1}px`,
          }}
        />
      )}

      {/* Flutter */}
      {/* React */}
      {scrollY > 5000 && (
        <img
          className="stats-icon-first"
          src={react}
          alt="react"
          style={{
            bottom: "230px",
            left: `${6015 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5050 && (
        <img
          className="stats-icon-second"
          src={react}
          alt="react"
          style={{
            bottom: "230px",
            left: `${6095 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5100 && (
        <img
          className="stats-icon-third"
          src={react}
          alt="react"
          style={{
            bottom: "230px",
            left: `${6180 - scrollY / 1}px`,
          }}
        />
      )}

      {/* React */}
      {/* HTML */}
      {scrollY > 5000 && (
        <img
          className="stats-icon-first"
          src={html}
          alt="html"
          style={{
            bottom: "330px",
            left: `${6015 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5050 && (
        <img
          className="stats-icon-second"
          src={html}
          alt="html"
          style={{
            bottom: "330px",
            left: `${6095 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5100 && (
        <img
          className="stats-icon-third"
          src={html}
          alt="html"
          style={{
            bottom: "330px",
            left: `${6180 - scrollY / 1}px`,
          }}
        />
      )}

      {/* HTML */}
      {/* Vue */}
      {scrollY > 5000 && (
        <img
          className="stats-icon-first"
          src={vue}
          alt="vue"
          style={{
            bottom: "430px",
            left: `${6015 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5050 && (
        <img
          className="stats-icon-second"
          src={vue}
          alt="vue"
          style={{
            bottom: "430px",
            left: `${6095 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5100 && (
        <img
          className="stats-icon-third"
          src={vue}
          alt="vue"
          style={{
            bottom: "430px",
            left: `${6180 - scrollY / 1}px`,
          }}
        />
      )}
    </div>
  );
};
