import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";

export const ArtSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 13500 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h1 style={{ margin: "0px" }}>Art</h1>
                <h3 style={{ marginTop: "5px" }}>
                  Adding onto the many passions of JC we now have his obsession
                  with painting. JC loves to paint whether it be canvas',
                  murals, or on clothing as long as he is painting, he is happy.
                </h3>
                <h3>
                  He included some pics of a recent mural he and two other
                  artists painted on the Hinton Center, a community club located
                  on the westside of his hometown, Fresno, CA.
                </h3>
              </div>
            </div>
          }
          parentLeft={`${14800 - scrollY / 1}px`}
          parentBottom={"360px"}
          parent2Left={`${14810 - scrollY / 1}px`}
          parent2Bottom={"370px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={"230px"}
        />
      )}
    </div>
  );
};
