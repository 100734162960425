import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import "./Firebase.css";

export const FirebaseSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 3600 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h1 style={{ marginTop: "3px" }}>Firebase Experience</h1>
                <h3>
                  Firebase is a Google-backed application development software
                  that enables developers to develop iOS, Android and Web apps.
                </h3>
                <div style={{ marginBottom: "5px" }}>
                  He's an even bigger fan of Firebase, he has 6 years working
                  with firebase on personal projects.
                </div>
                <div>
                  He has incepted and created many mobile applications using
                  Flutter for the front end and Firebase as the backend.
                </div>
              </div>
            </div>
          }
          parentLeft={`${4500 - scrollY / 1}px`}
          parentBottom={"240px"}
          parent2Left={`${4510 - scrollY / 1}px`}
          parent2Bottom={"250px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={"220px"}
        />
      )}
    </div>
  );
};
