import { useAppSelector } from "../../../app/hooks";
import { scrollDistance } from "../../../features/scroll/scrollSlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./Nikeintern.css";
import { useMediaQuery } from "react-responsive";

const nikeintern = require("../../../assets/nikeintern.png");
const nikeinternbanner = require("../../../assets/nikeinternbanner.png");

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  //indexAxis: 'y' as const,
  // maintainAspectRatio: false,
  // responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: true,
      labels: {
        color: "white",
        boxWidth: 40,
        padding: 10,
      },
    },
  },
};

const nikeinterndata = {
  labels: ["Front-End", "Back-End", "Cloud Engineering"],
  datasets: [
    {
      label: "Percent based on type of work",
      data: [40, 30, 30],
      backgroundColor: ["#000000", "#eeeeee", "#df6502"],
      borderColor: ["#000000", "#eeeeee", "#df6502"],
      borderWidth: 1,
    },
  ],
};

export const Nikeintern = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      <img
        src={nikeintern}
        alt="nikeintern"
        style={{
          position: "absolute",
          width: "218px",
          left: `${9063 - scrollY / 1}px`,
          bottom: "250px",
        }}
      ></img>
      <img
        src={nikeinternbanner}
        alt="nikeinternbanner"
        style={{
          position: "absolute",
          left: `${8909 - scrollY / 1}px`,
          bottom: "450px",
        }}
      ></img>
      <div
        style={{
          position: "absolute",
          left: `${9130 - scrollY / 1}px`,
          bottom: "665px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "black",
          width: "189px",
        }}
      >
        <div>Nike Inc.</div>
        <div>Portland, OR</div>
        <div>Software Engineer Internship</div>
      </div>

      <div
        style={{
          position: "absolute",
          left: `${8985 - scrollY / 1}px`,
          bottom: "672px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "black",
          width: "75px",
        }}
      >
        <div>May 2019</div>
        <div>to</div>
        <div>August 2019</div>
      </div>

      <div>
        {scrollY > 8000 && (
          <div
            style={{
              position: "absolute",
              left: `${8950 - scrollY}px`,
              bottom: "150px",
              height: "450px",
              width: "450px",
              zIndex: isTabletOrMobile ? "none" : "1",
            }}
          >
            <Doughnut data={nikeinterndata} options={options} />
          </div>
        )}
      </div>
    </div>
  );
};
