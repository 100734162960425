import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Firebase.css";

export const FirebaseInfo = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div
      className="firebase-info"
      style={{
        left: `${4500 - scrollY / 1}px`,
      }}
    >
      <h1
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        Firebase Resources
      </h1>
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginLeft: "20px" }}>
          <li>Realtime Database</li>
          <li>Firestore</li>
          <li>Storage</li>
          <li>Functions</li>
          <li>Authentication</li>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <li>Messaging</li>
          <li>Hosting</li>
          <li>In-App Messaging</li>
          <li>Hosting</li>
        </div>
      </div>
    </div>
  );
};
