import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Firebase.css";

const wood = require("../../assets/cave/wood.png");
const firebase = require("../../assets/firebase.png");

export const FirebaseWood = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      <img
        src={firebase}
        alt="firebase"
        className="firebase"
        style={{
          left: `${5400 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={wood}
        alt="wood"
        style={{
          position: "absolute",
          bottom: "40px",
          left: `${5305 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={wood}
        alt="wood"
        style={{
          position: "absolute",
          bottom: "40px",
          transform: "scaleX(-1)",
          left: `${5445 - scrollY / 1}px`,
        }}
      ></img>
    </div>
  );
};
