import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Firebase.css";

const firestore = require("../../assets/Cloud Firestore (1- Icon, Dark).png");
const functions = require("../../assets/Cloud Functions for Firebase (1- Icon, Dark).png");
const storage = require("../../assets/Cloud Storage for Firebase (1- Icon, Dark).png");
const auth = require("../../assets/Firebase Authentication (1- Icon, Dark).png");
const messaging = require("../../assets/Firebase Cloud Messaging (1- Icon, Dark).png");
const hosting = require("../../assets/Firebase Hosting (1- Icon, Dark).png");
const inappmessaging = require("../../assets/Firebase In-App Messaging (1- Icon, Dark).png");
const realtime = require("../../assets/Firebase Realtime Database (1- Icon, Dark).png");

export const FirebaseFire = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      <img
        src={firestore}
        alt="firestore"
        className="firebase-icons"
        style={{
          left: `${5470 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={functions}
        alt="functions"
        className="firebase-icons"
        style={{
          left: `${5420 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={storage}
        alt="storage"
        className="firebase-icons"
        style={{
          left: `${5470 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={messaging}
        alt="messaging"
        className="firebase-icons"
        style={{
          left: `${5420 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={hosting}
        alt="hosting"
        className="firebase-icons"
        style={{
          left: `${5470 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={inappmessaging}
        alt="inappmessaging"
        className="firebase-icons"
        style={{
          left: `${5420 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={realtime}
        alt="realtime"
        className="firebase-icons"
        style={{
          left: `${5470 - scrollY / 1}px`,
        }}
      ></img>
      <img
        src={auth}
        alt="auth"
        className="firebase-icons"
        style={{
          left: `${5420 - scrollY / 1}px`,
        }}
      ></img>
    </div>
  );
};
