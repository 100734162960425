import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import "./AWS.css";

export const AWSSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 2000 && (
        <div>
          <SpeechBubble
            childComp={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ flex: "1" }}>
                  <h1 style={{ marginTop: "5px" }}>AWS Experience</h1>
                  <h4>
                    Amazon Web Services (AWS) is the world’s most comprehensive
                    and broadly adopted cloud platform.
                  </h4>
                  <div  style={{ marginBottom: "5px"}}>
                    JC loves working with AWS, he's a big fan. He has 3 years
                    working with it in a professional enivornment.
                  </div>
                  <div>
                    He has worked on 15+ projects utilizing AWS resources with 11 of
                    them currently in production at his current job.
                  </div>
                </div>
              </div>
            }
            parentLeft={`${2890 - scrollY}px`}
            parentBottom={"190px"}
            parent2Left={`${2900 - scrollY}px`}
            parent2Bottom={"200px"}
            triangleLeft={"650px"}
            triangleBottom={"170px"}
            triangleRotate={"310deg"}
            triangle2left={"660px"}
            triangle2Bottom={"180px"}
            triangle2Rotate={"310deg"}
            scaleX={"1"}
            height={"200px"}
          />
        </div>
      )}
    </div>
  );
};
