import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import "./Skills.css";

const arrow = require("../../assets/arrow.png");

export const SkillsSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 5200 && (
        <div>
          <SpeechBubble
            childComp={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <h3>Front-End</h3>
                  <div>Vue</div>
                  <div>Html+Css</div>
                  <div>React</div>
                  <div>Flutter</div>
                  <img
                    src={arrow}
                    alt="arrow"
                    className="arrow1"
                    style={{
                      height: "75px",
                      width: "70px",
                      transform: "scaleX(-1) scaleY(1) rotate(25deg)",
                    }}
                  />
                </div>
                <div>
                  <h3>Programming</h3>
                  <div>Python</div>
                  <div>Typescript</div>
                  <div>Javascript</div>
                  <div>Dart</div>
                  <img
                    src={arrow}
                    alt="arrow"
                    className="arrow"
                    style={{
                      height: "75px",
                      width: "70px",
                      transform: "scaleX(1) scaleY(1) rotate(25deg)",
                    }}
                  />
                </div>
              </div>
            }
            parentLeft={`${6430- scrollY / 1}px`}
            parentBottom={"330px"}
            parent2Left={`${6440 - scrollY / 1}px`}
            parent2Bottom={"340px"}
            triangleLeft={"735px"}
            triangleBottom={"277px"}
            triangleRotate={"141deg"}
            triangle2left={"745px"}
            triangle2Bottom={"284px"}
            triangle2Rotate={"141deg"}
            scaleX={"-1"}
            height={"200px"}
          />
        </div>
      )}
    </div>
  );
};
