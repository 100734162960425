import { useAppSelector } from "../../../app/hooks";
import { scrollDistance } from "../../../features/scroll/scrollSlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./PGE.css";
import { useMediaQuery } from "react-responsive";

const pge = require("../../../assets/pge.png");
const pgebanner = require("../../../assets/pgebanner.png");

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  //indexAxis: 'y' as const,
  // maintainAspectRatio: false,
  // responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: true,
      labels: {
        color: "white",
        boxWidth: 40,
        padding: 10,
      },
    },
  },
};

export const pgedata = {
  labels: ["Data Engineering", "Back End"],
  datasets: [
    {
      label: "# of Votes",
      data: [80, 20],
      backgroundColor: ["#0075ac", "#e7aa00"],
      borderColor: ["#0075ac", "#e7aa00"],
      borderWidth: 1,
    },
  ],
};

export const PGE = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      <img
        src={pge}
        alt="pge"
        style={{
          position: "absolute",
          width: "386px",
          left: `${7982 - scrollY / 1}px`,
          bottom: "251px",
        }}
      ></img>
      <img
        src={pgebanner}
        alt="pgebanner"
        style={{
          position: "absolute",
          left: `${7909 - scrollY / 1}px`,
          bottom: "450px",
        }}
      ></img>
      <div
        style={{
          position: "absolute",
          left: `${8130 - scrollY / 1}px`,
          bottom: "665px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "159px",
        }}
      >
        <div>Pacific Gas & Electric Co.</div>
        <div>San Fransisco, CA</div>
        <div>Data Engineer Internship</div>
      </div>

      <div
        style={{
          position: "absolute",
          left: `${7985 - scrollY / 1}px`,
          bottom: "672px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "75px",
        }}
      >
        <div>May 2018</div>
        <div>to</div>
        <div>August 2018</div>
      </div>

      <div>
        {scrollY > 7000 && (
          <div
            style={{
              position: "absolute",
              left: `${7950 - scrollY}px`,
              bottom: "150px",
              height: "450px",
              width: "450px",
              zIndex: isTabletOrMobile ? "none" : "1",
            }}
          >
            <Doughnut data={pgedata} options={options} />
          </div>
        )}
      </div>
    </div>
  );
};
