import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import "./Form.css";

export const FormSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 18800 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h1 style={{ margin: "0px" }}>To Be Continued...</h1>
                <h3 style={{ marginTop: "5px" }}>
                  Well we've reached the end of the cave.
                </h3>
                <h3>
                  Hope you enjoyed walking with me and learning a little bit
                  about JC. If you have any questions or just want to chat,
                  please feel free to drop a message in the form to the right.
                  <h3> JC and I would love to hear from you.</h3>
                </h3>
                <h3>Thanks for stopping by!</h3>
              </div>
            </div>
          }
          parentLeft={`${19900 - scrollY / 1}px`}
          parentBottom={"360px"}
          parent2Left={`${19910 - scrollY / 1}px`}
          parent2Bottom={"370px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={"260px"}
        />
      )}
    </div>
  );
};
