import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Skills.css";

const dart = require("../../assets/dart.png");
const python = require("../../assets/python.png");
const js = require("../../assets/javascript.png");
const ts = require("../../assets/ts.png");

export const Programming = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      <div
        className="stats"
        style={{
          display: "flex",
          left: `${6900 - scrollY / 1}px`,
        }}
      >
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
        <hr
          style={{ transform: "rotate(180deg)", margin: "0px 0px 0px 80px" }}
        />
      </div>
      <div
        className="stats-competencies"
        style={{
          left: `${6900 - scrollY / 1}px`,
        }}
      />
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "540px",
          left: `${6900 - scrollY / 1}px`,
        }}
      >
        BEGINNER
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "580px",
          left: `${6975 - scrollY / 1}px`,
        }}
      >
        KNOWLEDGEABLE
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "540px",
          left: `${7050 - scrollY / 1}px`,
        }}
      >
        INTERMEDIATE
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "580px",
          left: `${7125 - scrollY / 1}px`,
        }}
      >
        COMPETENT
      </div>
      <div
        className="stats-competencies"
        style={{
          transform: "rotate(0deg)",
          background: "none",
          color: "white",
          height: "50px",
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: "540px",
          left: `${7200 - scrollY / 1}px`,
        }}
      >
        ADVANCED
      </div>
      {/* Icons */}
      {/* Dart */}
      {scrollY > 5500 && (
        <img
          className="stats-icon-first"
          src={dart}
          alt="dart"
          style={{
            bottom: "130px",
            left: `${6915 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5550 && (
        <img
          className="stats-icon-second"
          src={dart}
          alt="dart"
          style={{
            bottom: "130px",
            left: `${6995 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5600 && (
        <img
          className="stats-icon-third"
          src={dart}
          alt="dart"
          style={{
            bottom: "130px",
            left: `${7080 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5650 && (
        <img
          className="stats-icon-fourth"
          src={dart}
          alt="dart"
          style={{
            bottom: "130px",
            left: `${7160 - scrollY / 1}px`,
          }}
        />
      )}
      {/* dart */}
      {/* JS */}
      {scrollY > 5500 && (
        <img
          className="stats-icon-first"
          src={js}
          alt="js"
          style={{
            bottom: "230px",
            left: `${6915 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5550 && (
        <img
          className="stats-icon-second"
          src={js}
          alt="js"
          style={{
            bottom: "230px",
            left: `${6995 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5600 && (
        <img
          className="stats-icon-third"
          src={js}
          alt="js"
          style={{
            bottom: "230px",
            left: `${7080 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5650 && (
        <img
          className="stats-icon-fourth"
          src={js}
          alt="js"
          style={{
            bottom: "230px",
            left: `${7160 - scrollY / 1}px`,
          }}
        />
      )}
      {/* js */}
      {/* TS */}
      {scrollY > 5500 && (
        <img
          className="stats-icon-first"
          src={ts}
          alt="ts"
          style={{
            bottom: "330px",
            left: `${6915 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5550 && (
        <img
          className="stats-icon-second"
          src={ts}
          alt="ts"
          style={{
            bottom: "330px",
            left: `${6995 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5600 && (
        <img
          className="stats-icon-third"
          src={ts}
          alt="ts"
          style={{
            bottom: "330px",
            left: `${7080 - scrollY / 1}px`,
          }}
        />
      )}
      {/* ts */}
      {/* Python */}
      {scrollY > 5500 && (
        <img
          className="stats-icon-first"
          src={python}
          alt="python"
          style={{
            bottom: "430px",
            left: `${6915 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5550 && (
        <img
          className="stats-icon-second"
          src={python}
          alt="python"
          style={{
            bottom: "430px",
            left: `${6995 - scrollY / 1}px`,
          }}
        />
      )}
      {scrollY > 5600 && (
        <img
          className="stats-icon-third"
          src={python}
          alt="python"
          style={{
            bottom: "430px",
            left: `${7080 - scrollY / 1}px`,
          }}
        />
      )}
    </div>
  );
};
