import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import { useMediaQuery } from "react-responsive";
import "./Portland.css";

export const PortlandSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      {scrollY > 9999 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h3 style={{ marginTop: "5px" }}>
                  JC now resides in the great city of Portland since August
                  2020.
                </h3>
                <h3>
                  He is getting used to all the rain, and lack of sun at times,
                  but is starting to love the outdoor activities, the beauitful
                  greenery, and recently became a fan of the Porltand
                  Trailblazers since hes moved.
                </h3>
                <h3>
                  P.S. he told me they got some great breweries too, idk cheers
                  to that I guess. 🍺
                </h3>
              </div>
            </div>
          }
          parentLeft={`${11100 - scrollY / 1}px`}
          parentBottom={"220px"}
          parent2Left={`${11110 - scrollY / 1}px`}
          parent2Bottom={"230px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={isTabletOrMobile ? "240px" : "220px"}
        />
      )}
    </div>
  );
};
