import { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Music.css";

export const Music = () => {
  const scrollY = useAppSelector(scrollDistance);

  useEffect(() => {
    var element = document.querySelector(".amazingaudioplayer-mark");
    if (element) element.parentNode!.removeChild(element);
  }, []);

  return (
    <div
      id="amazingaudioplayer-1"
      style={{
        display: "block",
        position: "absolute",
        width: "400px",
        maxWidth: "600px",
        height: "auto",
        margin: "0px auto 0px",
        bottom: "450px",
        zIndex: "2",
        left: `${13488 - scrollY / 1}px`,
      }}
    >
      <ul className="amazingaudioplayer-audios" style={{ display: "none" }}>
        <li
          data-artist="JCJ"
          data-title="2 Drinks"
          data-album=""
          data-info=""
          data-image="audios/Screenshot%20%285%29.png"
          data-duration="309"
        >
          <div
            className="amazingaudioplayer-source"
            data-src="audios/TWO%20DRINKS%20Bpm%20140.mp3"
            data-type="audio/mpeg"
          ></div>
        </li>
        <li
          data-artist="JCJ"
          data-title="Ou Yeah"
          data-album=""
          data-info=""
          data-image="audios/Screenshot%20%285%29.png"
          data-duration="170"
        >
          <div
            className="amazingaudioplayer-source"
            data-src="audios/Ouu%20Yeah%20Bpm%20130_3.mp3"
            data-type="audio/mpeg"
          ></div>
        </li>
        <li
          data-artist="JCJ"
          data-title="Sole Music"
          data-album=""
          data-info=""
          data-image="audios/Screenshot%20%285%29.png"
          data-duration="208"
        >
          <div
            className="amazingaudioplayer-source"
            data-src="audios/Fire%20killer%20bpm%2090_2.mp3"
            data-type="audio/mpeg"
          ></div>
        </li>
      </ul>
    </div>
  );
};
