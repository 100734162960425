import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import "./Title.css";
import { useMediaQuery } from "react-responsive";

export const TitleSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      {!isTabletOrMobile && scrollY > 270 && (
        <SpeechBubble
          childComp={
            <div>
              <h1>Hi! I'm Octocat.</h1>
              <h2>
                Let me introduce you to one of the coolest, nicest, funniest,
                smartest guys I know.
              </h2>
              <h4>P.S. I only know one guy...lol</h4>
            </div>
          }
          parentLeft={`${950 - scrollY / 1}px`}
          parentBottom={"210px"}
          parent2Left={`${960 - scrollY / 1}px`}
          parent2Bottom={"220px"}
          triangleLeft={"735px"}
          triangleBottom={"277px"}
          triangleRotate={"141deg"}
          triangle2left={"745px"}
          triangle2Bottom={"284px"}
          triangle2Rotate={"141deg"}
          scaleX={"-1"}
          height={"220px"}
        />
      )}

      {isTabletOrMobile && (
        <SpeechBubble
          childComp={
            <div>
              <h1>Hi! I'm Octocat.</h1>
              <h2>
                Let me introduce you to one of the coolest, nicest, funniest,
                smartest guys I know.
              </h2>
              <h4>P.S. I only know one guy...lol</h4>
            </div>
          }
          parentLeft={`${430 - scrollY / 1}px`}
          parentBottom={"210px"}
          parent2Left={`${440 - scrollY / 1}px`}
          parent2Bottom={"220px"}
          triangleLeft={"735px"}
          triangleBottom={"277px"}
          triangleRotate={"141deg"}
          triangle2left={"745px"}
          triangle2Bottom={"284px"}
          triangle2Rotate={"141deg"}
          scaleX={"-1"}
          height={"220px"}
        />
      )}
    </div>
  );
};
