import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../components/SpeechBubble";
import "./Music.css";

export const MusicSpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 12000 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                  <h1 style={{margin: "0px"}}>Music</h1>
                <h3 style={{ marginTop: "5px" }}>
                  Before JC became a Software Engineer he spent a couple years
                  making music and working in a studio.
                </h3>
                <h3>
                  Music is definitely one of his passions and he still makes music
                  to this day, but it is more of a hobby now.
                </h3>
                <h3>He included some of his recent jams here to the right.</h3>
              </div>
            </div>
          }
          parentLeft={`${13000 - scrollY / 1}px`}
          parentBottom={"360px"}
          parent2Left={`${13010 - scrollY / 1}px`}
          parent2Bottom={"370px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={"200px"}
        />
      )}
    </div>
  );
};
