import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { RainComp } from "../../Rain";
import "./Portland.css";

const p = require("../../assets/cave/p.png");
const o = require("../../assets/cave/o.png");
const r = require("../../assets/cave/r.png");
const t = require("../../assets/cave/t.png");
const l = require("../../assets/cave/l.png");
const a = require("../../assets/cave/a.png");
const n = require("../../assets/cave/n.png");
const d = require("../../assets/cave/d.png");
const cloudrain = require("../../assets/cave/cloudtry.png");
const tree1 = require("../../assets/cave/treeline.png");

export const Portland = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      {scrollY > 9500 && scrollY < 11900 && (
        <>
          <RainComp left={`${11000 - scrollY / 1}px`} />
          <RainComp left={`${11200 - scrollY / 1}px`} />
          <RainComp left={`${11500 - scrollY / 1}px`} />
        </>
      )}
      {scrollY > 9500 && scrollY < 11900 && (
        <img
          src={cloudrain}
          alt="cloudrain"
          className="cloudsrain"
          style={{
            height: "260px",
            width: "700px",
            bottom: "527px",
            transform: " scaleX(-1)",
            left: `${11800 - scrollY / 1}px`,
          }}
        ></img>
      )}
      <img
        src={tree1}
        alt="tree1"
        className={
          scrollY > 9500 && scrollY < 11900 ? "trees-sprout" : "trees-unsprout"
        }
        style={{
          left: `${10900 - scrollY / 1}px`,
        }}
      ></img>
      {scrollY > 9500 && scrollY < 11900 && (
        <img
          src={cloudrain}
          alt="cloudrain"
          className="cloudsrain"
          style={{
            height: "260px",
            width: "1147px",
            bottom: "527px",
            transform: " scaleX(-1)",
            left: `${10900 - scrollY / 1}px`,
          }}
        ></img>
      )}
      {scrollY > 9500 && scrollY < 11900 && (
        <div
          className="portland-wrapper"
          style={{ left: `${11350 - scrollY / 1}px` }}
        >
          <img
            src={p}
            alt="p"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={o}
            alt="o"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={r}
            alt="r"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={t}
            alt="t"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={l}
            alt="l"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={a}
            alt="a"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={n}
            alt="n"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
          <img
            src={d}
            alt="d"
            style={{
              height: "100px",
              width: "100px",
              background: "none",
            }}
          ></img>
        </div>
      )}
    </div>
  );
};
