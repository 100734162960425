import React from "react";
import "./SpeechBubble.css";

interface ParentCompProps {
  childComp?: React.ReactNode;
  parentLeft: string;
  parentBottom: string;
  parent2Left: string;
  parent2Bottom: string;
  triangleLeft: string;
  triangleBottom: string;
  triangleRotate: string;
  triangle2left: string;
  triangle2Bottom: string;
  triangle2Rotate: string;
  scaleX: string;
  height: string;
}

export const SpeechBubble: React.FC<ParentCompProps> = (props) => {
  const {
    childComp,
    parentLeft,
    parentBottom,
    parent2Left,
    parent2Bottom,
    height,
  } = props;

  return (
    <div>
      <div
        className="speech-bubble"
        style={{
          left: parentLeft,
          bottom: parentBottom,
          height: height,
        }}
      ></div>
      <div
        className="sppechvisual"
        style={{
          left: parent2Left,
          bottom: parent2Bottom,
          height: height,
          overflow: "hidden"
        }}
      >
        {childComp}
      </div>
    </div>
  );
};
