import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import { useMediaQuery } from "react-responsive";
import "./Projects.css";

const trump0 = require("../../assets/nicetweetstry.mp4");
// const trump1 = require("../../assets/trump1.mp4");
// const trump2 = require("../../assets/trump2.mp4");
// const trump3 = require("../../assets/trump3.mp4");
const shoe_desc = require("../../assets/shoebxtesttry.mp4");
// const search = require("../../assets/search1.mp4");
// const profile = require("../../assets/profile1.mp4");
// const social = require("../../assets/shoebxtesttry.mp4");

export const Projects = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      {/* <div style={{ display: scrollY > 18000 ? "none" : "block" }}> */}
      <div>
        <h1
          style={{
            position: "absolute",
            bottom: "700px",
            left: `${17690 - scrollY / 1}px`,
            width: isTabletOrMobile ? "510px" : "410px",
          }}
        >
          Nice Tweets (Multiplayer Game)
        </h1>
        <h3
          style={{
            position: "absolute",
            bottom: "660px",
            left: `${17560 - scrollY / 1}px`,
            width: isTabletOrMobile ? "890px" : "700px",
            textAlign: "center",
          }}
        >
          Cross platform application built with Flutter using Firebase as the
          backend
        </h3>
        <video
          src={trump0}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${17380 - scrollY / 1}px`,
          }}
        ></video>
        {/* <video
          src={trump1}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${17630 - scrollY / 1}px`,
          }}
        ></video>
        <video
          src={trump2}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${17920 - scrollY / 1}px`,
          }}
        ></video>
        <video
          src={trump3}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${18210 - scrollY / 1}px`,
          }}
        ></video> */}
      </div>
      {/* <div style={{ display: (scrollY < 18181 || scrollY > 19550) ? "none" : "block" }}> */}
      <div>
        <h1
          style={{
            position: "absolute",
            bottom: "700px",
            left: `${18850 - scrollY / 1}px`,
            width: isTabletOrMobile ? "510px" : "420px",
          }}
        >
          ShoeBX (Untappd but for Shoes)
        </h1>
        <h3
          style={{
            position: "absolute",
            bottom: "660px",
            left: `${18710 - scrollY / 1}px`,
            width: isTabletOrMobile ? "890px" : "700px",
            textAlign: "center",
          }}
        >
          Cross platform application built with Flutter using Firebase as the
          backend
        </h3>
        <video
          src={shoe_desc}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${18580 - scrollY / 1}px`,
          }}
        ></video>
        {/* <video
          src={search}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${18830 - scrollY / 1}px`,
          }}
        ></video>
        <video
          src={profile}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${19080 - scrollY / 1}px`,
          }}
        ></video>
        <video
          src={social}
          autoPlay
          playsInline
          muted
          loop
          className="videos"
          style={{
            left: `${19330 - scrollY / 1}px`,
          }}
        ></video> */}
      </div>
    </div>
  );
};
