import { useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Music.css";

const studio = require("../../assets/studio.png");
const bass = require("../../assets/bass.png");
const keyboard = require("../../assets/keyboard.png");

export const Studio = () => {
  const scrollY = useAppSelector(scrollDistance);

  useEffect(() => {
    var element = document.querySelector(".amazingaudioplayer-mark");
    if (element) element.parentNode!.removeChild(element);
  }, []);

  return (
    <div>
      <div
        style={{
          position: "absolute",
          bottom: "-100px",
          left: `${13050 - scrollY / 1}px`,
        }}
      >
        <img
          src={studio}
          alt="studio"
          style={{
            height: "636px",
            background: "none",
          }}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: `${14050 - scrollY / 1}px`,
          zIndex: "3",
        }}
      >
        <img
          src={bass}
          alt="bass"
          style={{
            height: "120px",
            background: "none",
          }}
        ></img>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: `${13150 - scrollY / 1}px`,
          zIndex: "3",
        }}
      >
        <img
          src={keyboard}
          alt="keyboard"
          style={{
            height: "180px",
            background: "none",
          }}
        ></img>
      </div>
    </div>
  );
};
