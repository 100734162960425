import { FC, useMemo, useEffect } from "react";
import { useRive, UseRiveParameters, Layout, Fit, Alignment } from "rive-react";
import { useAppSelector } from "./app/hooks";
import { scrollDirection } from "./features/scroll/scrollSlice";

interface RiveProps {
  left: string;
}

export const RainComp: FC<RiveProps> = ({ left }) => {
  const direction = useAppSelector(scrollDirection);

  const parms: UseRiveParameters = {
    src: "../rain.riv",
    autoplay: true,
    artboard: "New Artboard",
    animations: "Untitled 1",
    layout: new Layout({ fit: Fit.Contain, alignment: Alignment.BottomCenter }),
  };

  const { rive, RiveComponent } = useRive(parms);
  useEffect(() => {
    if (rive) {
      if (direction === "UP") {
        // setCatDirection(-1);
        if (!rive.isPlaying) {
          rive.play();
        }
      } else if (direction === "DOWN") {
        // setCatDirection(1);
        if (!rive.isPlaying) {
          rive.play();
        }
      } else {
        rive.play();
      }
    }
  }, [direction, rive]);

  return useMemo(
    () => (
      <div
        style={{
          height: "800px",
          width: "650px",
          bottom: "-150px",
          left: left,
          position: "absolute",
          overflow: "hidden",
        }}
      >
        <RiveComponent />
      </div>
    ),
    [RiveComponent, left]
  );
};
