import { useAppSelector } from "../../app/hooks";
import { scrollDistance } from "../../features/scroll/scrollSlice";
import "./Art.css";

const art1 = require("../../assets/art0.jpg");
const art2 = require("../../assets/art1.jpg");
const art3 = require("../../assets/art2.jpg");
const art4 = require("../../assets/art3.jpg");
const art5 = require("../../assets/art4.jpg");

export const Art = () => {
  const scrollY = useAppSelector(scrollDistance);

  return (
    <div>
      <img
        src={art4}
        alt="art4"
        style={{
          position: "absolute",
          bottom: "460px",
          left: `${15380 - scrollY / 1}px`,
          height:
            scrollY > 14400
              ? scrollY - 14400 > 300
                ? "300px"
                : `${scrollY - 14400}px`
              : "0px",
          width:
            scrollY > 14400
              ? scrollY - 14400 > 300
                ? "300px"
                : `${scrollY - 14400}px`
              : "0px",
          background: "none",
          borderRadius: "20px",
        }}
      ></img>
      <img
        src={art5}
        alt="art5"
        style={{
          position: "absolute",
          bottom: "150px",
          left: `${15580 - scrollY / 1}px`,
          height:
            scrollY > 14600
              ? scrollY - 14600 > 300
                ? "300px"
                : `${scrollY - 14600}px`
              : "0px",
          width:
            scrollY > 14600
              ? scrollY - 14600 > 300
                ? "300px"
                : `${scrollY - 14600}px`
              : "0px",
          background: "none",
          borderRadius: "20px",
        }}
      ></img>
      <img
        src={art1}
        alt="art1"
        style={{
          position: "absolute",
          bottom: "460px",
          left: `${15780 - scrollY / 1}px`,
          height:
            scrollY > 14800
              ? scrollY - 14800 > 300
                ? "300px"
                : `${scrollY - 14800}px`
              : "0px",
          width:
            scrollY > 14800
              ? scrollY - 14800 > 300
                ? "300px"
                : `${scrollY - 14800}px`
              : "0px",
          background: "none",
          borderRadius: "20px",
        }}
      ></img>
      <img
        src={art2}
        alt="art2"
        style={{
          position: "absolute",
          bottom: "150px",
          left: `${15980 - scrollY / 1}px`,
          height:
            scrollY > 15000
              ? scrollY - 15000 > 300
                ? "300px"
                : `${scrollY - 15000}px`
              : "0px",
          width:
            scrollY > 15000
              ? scrollY - 15000 > 300
                ? "300px"
                : `${scrollY - 15000}px`
              : "0px",
          background: "none",
          borderRadius: "20px",
        }}
      ></img>
      <img
        src={art3}
        alt="art3"
        style={{
          position: "absolute",
          bottom: "460px",
          left: `${16180 - scrollY / 1}px`,
          height:
            scrollY > 15200
              ? scrollY - 15200 > 300
                ? "300px"
                : `${scrollY - 15200}px`
              : "0px",
          width:
            scrollY > 15200
              ? scrollY - 15200 > 300
                ? "300px"
                : `${scrollY - 15200}px`
              : "0px",
          background: "none",
          borderRadius: "20px",
        }}
      ></img>
    </div>
  );
};
