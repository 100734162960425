import { useAppSelector } from "../../../app/hooks";
import { scrollDistance } from "../../../features/scroll/scrollSlice";
import { SpeechBubble } from "../../../components/SpeechBubble";
import { useMediaQuery } from "react-responsive";
import "./PGE.css";

export const PGESpeechBubble = () => {
  const scrollY = useAppSelector(scrollDistance);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 770px)" });

  return (
    <div>
      {scrollY > 6500 && (
        <SpeechBubble
          childComp={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div style={{ flex: "1" }}>
                <h3 style={{ marginTop: "5px" }}>
                  He's worked at Pacific Gas and Electric Company based out of
                  San Fransisco as a Data Engineer intern back in 2018. (many
                  moons ago)
                </h3>
              </div>
            </div>
          }
          parentLeft={`${7500 - scrollY / 1}px`}
          parentBottom={"420px"}
          parent2Left={`${7510 - scrollY / 1}px`}
          parent2Bottom={"430px"}
          triangleLeft={"650px"}
          triangleBottom={"170px"}
          triangleRotate={"310deg"}
          triangle2left={"660px"}
          triangle2Bottom={"180px"}
          triangle2Rotate={"310deg"}
          scaleX={"1"}
          height={isTabletOrMobile ? "90px" : "70px"}
        />
      )}
    </div>
  );
};
