import { FC, RefObject, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  changeScrollDistance,
  changeScrollDirection,
} from "../../features/scroll/scrollSlice";
import { useScrollDirection } from "use-scroll-direction";

interface ExampleProps {
  elementRef: RefObject<HTMLDivElement>
}

export const Resume: FC<ExampleProps> =  ({ elementRef }) => {
  const dispatch = useAppDispatch();
  const { scrollDirection } = useScrollDirection({ ref: elementRef });

  useEffect(() => {
    dispatch(changeScrollDirection(scrollDirection.toString()));
  }, [dispatch, scrollDirection]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        overflowY: "scroll",
        position: "relative",
      }}
      ref={elementRef}
      onScroll={(event) => {
        dispatch(changeScrollDistance((event.target as HTMLElement).scrollTop));
      }}
    >
      <div style={{ height: "21500px" }}></div>
    </div>
  );
};
