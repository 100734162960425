import { Resume } from "./pages/resume/Resume";
import { useAppSelector } from "./app/hooks";
import { scrollDistance } from "./features/scroll/scrollSlice";
import { RiveComp } from "./Rive";
import { Title } from "./pages/title/Title";
import { TitleSpeechBubble } from "./pages/title/TitleSpeechBubble";
import { FrontEnd } from "./pages/skills/FrontEnd";
import { Programming } from "./pages/skills/Programming";
import { SkillsSpeechBubble } from "./pages/skills/SkillsSpeechBubble";
import { AWSResources } from "./pages/aws/AWSResources";
import { AWSSpeechBubble } from "./pages/aws/AWSSpeechBubble";
import { AWSCloud } from "./pages/aws/AWSCloud";
import { FirebaseFire } from "./pages/firebase/FirebaseFire";
import { FirebaseInfo } from "./pages/firebase/FirebaseInfo";
import { FirebaseWood } from "./pages/firebase/FirebaseWood";
import { FirebaseSpeechBubble } from "./pages/firebase/FirebaseSpeechbubble";
import { Portland } from "./pages/portland/Portland";
import { PortlandSpeechBubble } from "./pages/portland/PortlandSpeechBubble";
import { Fresno } from "./pages/fresno/Fresno";
import { FresnoSpeechBubble } from "./pages/fresno/FresnoSpeechBubble";
import { PGE } from "./pages/work/pge/PGE";
import { PGESpeechBubble } from "./pages/work/pge/PGESpeechBubble";
import { Nikeintern } from "./pages/work/nikeintern/Nikeintern";
import { NikeinternSpeechBubble } from "./pages/work/nikeintern/NikeinternSpeechBubble";
import { Nike } from "./pages/work/nike/Nike";
import { NikeSpeechBubble } from "./pages/work/nike/NikeSpeechBubble";
import { Music } from "./pages/audio/Music";
import { Studio } from "./pages/audio/Studio";
import { MusicSpeechBubble } from "./pages/audio/MusicSpeechBubble";
import { Art } from "./pages/art/Art";
import { ArtSpeechBubble } from "./pages/art/ArtSpeechBubble";
import { Projects } from "./pages/projects/Projects";
import { ProjectsSpeechBubble } from "./pages/projects/ProjectsSpeechBubble";
import { Form } from "./pages/form/Form";
import { FormSpeechBubble } from "./pages/form/FormSpeechBubble";
import { HamburgerMenu } from "./pages/resume/Hamburger";
import "./App.css";
import { useRef } from "react";

export const App = () => {
  const scrollY = useAppSelector(scrollDistance);
  const elementRef = useRef<HTMLDivElement>(null);

  console.log(scrollY);

  return (
    <div className="app">
      <div className="bgcolor" />
      <div className="stars" style={{ left: `-${scrollY / 4}px` }} />
      <div className="moon" style={{ left: `-${scrollY / 20}px` }} />
      <div
        className="rightbackmountain"
        style={{ left: `-${scrollY / 2}px` }}
      />
      <div className="leftbackmountain" style={{ left: `-${scrollY / 4}px` }} />
      <div
        className="leftmiddlerockpick"
        style={{ left: `-${scrollY / 2.5}px` }}
      />
      <div
        className="leftmountainmiddle"
        style={{ left: `-${scrollY / 3}px` }}
      />
      <div className="rightmountain" style={{ left: `-${scrollY / 3.5}px` }} />
      <div className="leftmountain" style={{ left: `-${scrollY / 2.5}px` }} />
      <div
        className="rightmiddlerockpick"
        style={{ left: `-${scrollY / 2.5}px` }}
      />
      <div
        className="leftmountainfloorpick"
        style={{ left: `-${scrollY / 2.2}px` }}
      />
      <div className="smokebackground" style={{ left: `-${scrollY / 2}px` }} />
      <div className="rockfloor" style={{ left: `-${scrollY / 2.5}px` }} />
      <div className="frontshadow" style={{ left: `-${scrollY / 2.5}px` }} />
      <div className="darkener" style={{ left: `-${scrollY / 2.5}px` }} />
      <div className="otherastro" style={{ left: `-${scrollY / 1}px` }} />
      <div className="shineline" style={{ left: `-${scrollY / 2.5}px` }} />

      <TitleSpeechBubble />
      <Title />

      <Fresno />
      <FresnoSpeechBubble />

      <AWSResources />
      <AWSSpeechBubble />
      <AWSCloud />

      <FirebaseFire />
      <FirebaseInfo />
      <FirebaseWood />
      <FirebaseSpeechBubble />

      <FrontEnd />
      <SkillsSpeechBubble />
      <Programming />

      <PGE />
      <PGESpeechBubble />

      <Nikeintern />
      <NikeinternSpeechBubble />

      <Nike />
      <NikeSpeechBubble />

      <RiveComp />

      <Portland />
      <PortlandSpeechBubble />

      <Studio />
      <Music />
      <MusicSpeechBubble />

      <Art />
      <ArtSpeechBubble />

      <Projects />
      <ProjectsSpeechBubble />

      <Form />
      <FormSpeechBubble />

      <HamburgerMenu elementRef={elementRef} />
      <Resume elementRef={elementRef} />
      <a
        href="https://www.linkedin.com/in/jesus-covarrubias-jr-290a26172"
        target="_blank"
        rel="noreferrer"
      >
        <i
          className="fa-brands fa-linkedin"
          style={{
            fontSize: "25px",
            position: "absolute",
            right: "40px",
            bottom: "30px",
            color: "white",
            zIndex: "5",
          }}
        ></i>
      </a>
      <a
        href="https://github.com/jcovarrubias11"
        target="_blank"
        rel="noreferrer"
      >
        <i
          className="fa-brands fa-github"
          style={{
            fontSize: "25px",
            position: "absolute",
            right: "80px",
            bottom: "30px",
            color: "white",
            zIndex: "5",
          }}
        ></i>
      </a>
    </div>
  );
};
